import GreyCliffBoldObliqueEOT from "./greycliff-cf-bold-oblique.eot";
import GreyCliffBoldObliqueTTF from "./greycliff-cf-bold-oblique.ttf";

import GreyCliffBoldEOT from "./greycliff-cf-bold.eot";
import GreyCliffBoldTTF from "./greycliff-cf-bold.ttf";

import GreyCliffDemiBoldObliqueEOT from "./greycliff-cf-demi-bold-oblique.eot";
import GreyCliffDemiBoldObliqueTTF from "./greycliff-cf-demi-bold-oblique.ttf";

import GreyCliffDemiBoldEOT from "./greycliff-cf-demi-bold.eot";
import GreyCliffDemiBoldTTF from "./greycliff-cf-demi-bold.ttf";

import GreyCliffExtraBoldEOT from "./greycliff-cf-extra-bold.eot";
import GreyCliffExtraBoldTTF from "./greycliff-cf-extra-bold.ttf";

import GreyCliffExtraBoldObliqueEOT from "./greycliff-cf-extra-bold-oblique.eot";
import GreyCliffExtraBoldObliqueTTF from "./greycliff-cf-extra-bold-oblique.ttf";

import GreyCliffHeavyObliqueEOT from "./greycliff-cf-heavy-oblique.eot";
import GreyCliffHeavyObliqueTTF from "./greycliff-cf-heavy-oblique.ttf";

import GreyCliffHeavyEOT from "./greycliff-cf-heavy.eot";
import GreyCliffHeavyTTF from "./greycliff-cf-heavy.ttf";

import GreyCliffLightObliqueEOT from "./greycliff-cf-light-oblique.eot";
import GreyCliffLightObliqueTTF from "./greycliff-cf-light-oblique.ttf";

import GreyCliffLightEOT from "./greycliff-cf-light.eot";
import GreyCliffLightTTF from "./greycliff-cf-light.ttf";

import GreyCliffMediumObliqueEOT from "./greycliff-cf-medium-oblique.eot";
import GreyCliffMediumObliqueTTF from "./greycliff-cf-medium-oblique.ttf";

import GreyCliffMediumEOT from "./greycliff-cf-medium.eot";
import GreyCliffMediumTTF from "./greycliff-cf-medium.ttf";

import GreyCliffRegularObliqueEOT from "./greycliff-cf-regular-oblique.eot";
import GreyCliffRegularObliqueTTF from "./greycliff-cf-regular-oblique.ttf";

import GreyCliffRegularEOT from "./greycliff-cf-regular.eot";
import GreyCliffRegularTTF from "./greycliff-cf-regular.ttf";

import MontserratBoldWoff from "./montserrat-bold2.woff";
import MontserratBoldWoff2 from "./montserrat-bold2.woff2";

import MontserratMediumWoff from "./montserrat-medium2.woff";
import MontserratMediumWoff2 from "./montserrat-medium2.woff2";

export const fonts = `
  @font-face {
      font-family: "greycliff";
      src: url(${GreyCliffBoldObliqueEOT}) format('embedded-opentype'),
              url(${GreyCliffBoldObliqueTTF}) format('truetype');
      font-weight: bold;
      font-style: italic;
  }
  @font-face {
      font-family: "greycliff";
      src: url(${GreyCliffBoldEOT}) format('embedded-opentype'),
              url(${GreyCliffBoldTTF}) format('truetype');
      font-weight: bold;
      font-style: normal;
  }
  @font-face {
      font-family: "greycliff";
      src: url(${GreyCliffDemiBoldObliqueEOT}) format('embedded-opentype'),
              url(${GreyCliffDemiBoldObliqueTTF}) format('truetype');
      font-weight: 300;
      font-style: italic;
  }
  @font-face {
      font-family: "greycliff";
      src: url(${GreyCliffDemiBoldEOT}) format('embedded-opentype'),
              url(${GreyCliffDemiBoldTTF}) format('truetype');
      font-weight: 300;
      font-style: normal;
  }
  @font-face {
      font-family: "Greycliff";
      src: url(${GreyCliffExtraBoldObliqueEOT}) format('embedded-opentype'),
              url(${GreyCliffExtraBoldObliqueTTF}) format('truetype');
      font-weight: 600;
      font-style: italic;
  }
  @font-face {
      font-family: "greycliff";
      src: url(${GreyCliffExtraBoldEOT}) format('embedded-opentype'),
              url(${GreyCliffExtraBoldTTF}) format('truetype');
      font-weight: 600;
      font-style: normal;
  }
  @font-face {
      font-family: "greycliff";
      src: url(${GreyCliffHeavyObliqueEOT}) format('embedded-opentype'),
              url(${GreyCliffHeavyObliqueTTF}) format('truetype');
      font-weight: 900;
      font-style: italic;
  }
  @font-face {
      font-family: "greycliff";
      src: url(${GreyCliffHeavyEOT}) format('embedded-opentype'),
              url(${GreyCliffHeavyTTF}) format('truetype');
      font-weight: 900;
      font-style: normal;
  }
  @font-face {
      font-family: "greycliff";
      src: url(${GreyCliffLightObliqueEOT}) format('embedded-opentype'),
              url(${GreyCliffLightObliqueTTF}) format('truetype');
      font-weight: 100;
      font-style: italic;
  }
  @font-face {
      font-family: "greycliff";
      src: url(${GreyCliffLightEOT}) format('embedded-opentype'),
              url(${GreyCliffLightTTF}) format('truetype');
      font-weight: 100;
      font-style: normal;
  }
  @font-face {
      font-family: "greycliff";
      src: url(${GreyCliffMediumObliqueEOT}) format('embedded-opentype'),
              url(${GreyCliffMediumObliqueTTF}) format('truetype');
      font-weight: 300;
      font-style: italic;
  }
  @font-face {
      font-family: "greycliff";
      src: url(${GreyCliffMediumEOT}) format('embedded-opentype'),
              url(${GreyCliffMediumTTF}) format('truetype');
      font-weight: 300;
      font-style: normal;
  }
  @font-face {
      font-family: "greycliff";
      src: url(${GreyCliffRegularObliqueEOT}) format('embedded-opentype'),
              url(${GreyCliffRegularObliqueTTF}) format('truetype');
      font-weight: normal;
      font-style: italic;
  }
  @font-face {
      font-family: "greycliff";
      src: url(${GreyCliffRegularEOT}) format('embedded-opentype'),
              url(${GreyCliffRegularTTF}) format('truetype');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratBoldWoff}) format('woff2'),
        url(${MontserratBoldWoff2}) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratMediumWoff}) format('woff2'),
        url(${MontserratMediumWoff2}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
`;
