import React, { useEffect } from "react";
import Showdown from "showdown";

////////////////////////////////////////////////////////////////////////

export const isWindow = typeof window !== "undefined";

const isDocument = typeof document !== "undefined";
const threshold = 30;

var headerHeight = "";

if (isWindow) {
	setTimeout(() => {
		let header = document.querySelector("header");
		if (header && header.clientHeight) {
			headerHeight = header.clientHeight;
		}
	}, 2000);
}

////////////////////////////////////////////////////////////////////////

function touchDeviceCheck() {
	if (isDocument) {
		try {
			document.createEvent("TouchEvent");
			return true;
		} catch (e) {
			return false;
		}
	}
}

////////////////////////////////////////////////////////////////////////

export const interaction = touchDeviceCheck() ? "touchstart" : "click";
export const isiAnchorThreshold = 300;

/**************************/
/*
    start - device
    notes: device breakpoints
*/

export const device = {
	mobile: `only screen and (max-width: 767px)`,
	betweenTabletAndDesktop: `only screen and (min-width: 768px) and (max-width: 1199px)`,
	tabletBelow: `only screen and (max-width: 991px)`,
	tablet: `only screen and (min-width: 767px) and (max-width: 991px)`,
	desktop: `only screen and (min-width: 992px) and (max-width: 1199px)`,
	desktop_max: `only screen and (min-width: 1199px) and (max-width: 1280px)`,
	desktop_lg: `only screen and (min-width: 1200px)`,
	desktop_xlg: `only screen and (min-width: 1281px)`,
	desktop_isi: `only screen and (max-width: 1315px)`,
	desktop_toMobile: `only screen and (max-width: 1189px)`,
	desktop_xlgmx: `only screen and (max-width: 1280px)`,
	ie11: `screen and (-ms-high-contrast: active), (-ms-high-contrast: none)`,
};

/* end - device */
/************************/

/**************************/
/*
    start - createMarkup()
    notes: Returns an html object
*/
export function createMarkup(htmlObj) {
	return { __html: htmlObj };
}
/* end - createMarkup() */
/************************/

/**************************/
/*
    start - convertMarkdown()
    notes:  Takes html markup as a string
            Showdown is a Javascript Markdown to HTML converter (https://github.com/showdownjs/showdown)
            Function will return injecting a div element containing the converted markdown to html
*/
export function convertMarkdown(mark) {
	var converter = new Showdown.Converter();

	return (
		<div dangerouslySetInnerHTML={createMarkup(converter.makeHtml(mark))} />
	);
}
/* end - convertMarkdown() */
/************************/

///////////////////////////////////////////////////////////////////////////////////////

export const adjustScrollPosition = (e, section) => {
	headerHeight = document.querySelector("header").clientHeight;

	const linkTimeout = isiAnchorThreshold;

	let sectionYposition = "";

	const scrollToSection = (posY) => {
		if (isWindow) {
			window.scrollTo({
				top: posY - (headerHeight + threshold),
				behavior: "smooth",
			});
		}
	};

	if (isWindow) {
		if (e) {
			if (e.target.parentNode.parentNode.parentNode.id === "navigation") {
				e.preventDefault();
				setTimeout(() => {
					const _linkedSection = document.getElementById(
						e.target.parentNode.dataset.link
					);
					window.location.hash = _linkedSection.id;
					sectionYposition = _linkedSection.offsetTop;
					scrollToSection(sectionYposition);
				}, linkTimeout);
			}
		} else if (section) {
			setTimeout(() => {
				scrollToSection(document.getElementById(section).offsetTop);
			}, 1000);
		} else {
			const hashTrigger = isWindow ? window.location.hash : null;
			const needSubNav = document
				.querySelector("header")
				.classList.contains("secondary");
			let _initialSection = "";

			setTimeout(() => {
				if (needSubNav) {
					if (hashTrigger.length > 0) {
						if (hashTrigger.indexOf("?") > 0) {
							const hashfinder = hashTrigger.indexOf("?");
							const newhash = hashTrigger.slice(1, hashfinder);
							_initialSection = document.getElementById(newhash);
						} else {
							_initialSection = document.getElementById(
								hashTrigger.substring(1)
							);
						}
					} else {
						_initialSection = document.querySelector(
							`.md-container > section:nth-of-type(1)`
						);
					}

					sectionYposition = _initialSection.offsetTop;
					scrollToSection(sectionYposition);
				}
			}, linkTimeout);
		}
	}
};

export const AnchorLinking = (props) => {
	useEffect(() => {
		setTimeout(() => {
			const _anchorLinks = document.querySelectorAll(".anchor-link");
			let section = "";
			let posY = "";
			let anchorThreshold = "";
			let customThreshold = props.threshold;

			customThreshold > -1
				? (anchorThreshold = customThreshold)
				: (anchorThreshold = threshold);

			_anchorLinks.forEach((el) => {
				el.addEventListener(interaction, (e) => {
					e.preventDefault();
					section = el.hash.substring(1);
					posY = document.getElementById(section).offsetTop;

					if (isWindow) {
						window.scrollTo({
							top: posY - (headerHeight + anchorThreshold),
							behavior: "smooth",
						});
					}
				});
			});
		}, 400);
	});

	return null;
};

////////////////////////////////////////////////////////////////////////////////

export const scrollToTop = (behavior) => {
	if (behavior !== undefined) {
		behavior = `smooth`;
	}

	if (isWindow) {
		document.querySelector("body").scrollTo({
			top: 0,
			behavior: behavior,
		});
	}
};
// Opera 8.0+
const isOpera =
isWindow && ((!!window.opr && !!window.opr.addons) ||
  !!window.opera ||
  navigator.userAgent.indexOf(' OPR/') >= 0);

// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
const isSafari = isWindow && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/ isWindow && (false || !!document.documentMode);

// Edge 20+
const isEdge = isWindow && (!isIE && !!window.StyleMedia);

// Chrome 1 - 79
const isChrome =
isWindow && (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime));

// Edge (based on chromium) detection
const isEdgeChromium = isChrome && navigator.userAgent.indexOf('Edg') !== -1;

// Blink engine detection
const isBlink = (isChrome || isOpera) && !!window.CSS;

export const Browser =  {
	isOpera,
	isFirefox,
	isSafari,
	isIE,
	isEdge,
	isChrome,
	isEdgeChromium,
	isBlink,
};