// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-coverage-js": () => import("./../../../src/pages/coverage.js" /* webpackChunkName: "component---src-pages-coverage-js" */),
  "component---src-pages-culture-conversion-js": () => import("./../../../src/pages/culture-conversion.js" /* webpackChunkName: "component---src-pages-culture-conversion-js" */),
  "component---src-pages-dosing-js": () => import("./../../../src/pages/dosing.js" /* webpackChunkName: "component---src-pages-dosing-js" */),
  "component---src-pages-guidelines-js": () => import("./../../../src/pages/guidelines.js" /* webpackChunkName: "component---src-pages-guidelines-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patient-profiles-js": () => import("./../../../src/pages/patient-profiles.js" /* webpackChunkName: "component---src-pages-patient-profiles-js" */),
  "component---src-pages-safety-js": () => import("./../../../src/pages/safety.js" /* webpackChunkName: "component---src-pages-safety-js" */),
  "component---src-pages-site-map-js": () => import("./../../../src/pages/site-map.js" /* webpackChunkName: "component---src-pages-site-map-js" */),
  "component---src-pages-summary-js": () => import("./../../../src/pages/summary.js" /* webpackChunkName: "component---src-pages-summary-js" */),
  "component---src-pages-target-mac-js": () => import("./../../../src/pages/target-mac.js" /* webpackChunkName: "component---src-pages-target-mac-js" */),
  "component---src-pages-why-treat-refractory-mac-js": () => import("./../../../src/pages/why-treat-refractory-mac.js" /* webpackChunkName: "component---src-pages-why-treat-refractory-mac-js" */)
}

