import React from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import Theme from "./src/themes/theme";
import { fonts } from "./static/fonts/";
import DownloadIcon from "./static/images/button-download.svg";
import { device } from "./src/components/Helpers";

const GlobalStyles = createGlobalStyle`
	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
	}

	${fonts}
	html {
		height: 100%;
		overflow: hidden;
		@media only screen and (max-height: 650px) and (min-width: 768px) {
			transform: scale(0.75);
			height: 133.3333% !important;
			top: -16.6665%;
			left: -16.6665%;
			position: absolute;
			width: 133.3333%;
			.modal-content-wrap {
				height: calc(133.3333vh - 505px);
			}
			#main-wrap {
				&:before {
					border-top: 12px solid #fff;
				}
			}
			nav {
				width: 100% !important;
				max-width: 1170px!important;
				min-width: 300px!important;
			}
			#mobile-menu {
				margin-right: 1.5vw !important;
			}
			.prevNavBtn {
				margin-right: -5rem !important;
			}
			.nextNavBtn {
				margin-right: 2rem !important;
			}
			#arikayce-logo {
				margin-left: 0rem !important;
			}
			#arikayce-logo > img {
				margin-left: 0rem !important;
				width: 150% !important;
			}
		}
		@media only screen and (max-height: 650px) and (min-width: 1190px) {
			.prevNavBtn {
				right: 13.5rem !important;
				margin-right: 0 !important;
			}
			.nextNavBtn {
				right: 7.5rem !important;
				margin-right: 0 !important;
			}
			#mobile-menu {
				margin-right: 2.35vw !important;
			}
			nav {
				width: 100% !important;
				max-width: 1170px!important;
				min-width: 300px!important;
			}
		}
		@media only screen and (max-height: 650px) and (min-width: 1281px) {
			.prevNavBtn {
				right: 12.5rem !important;
				margin-right: 0 !important;
			}
			.nextNavBtn {
				right: 6.5rem !important;
				margin-right: 0 !important;
			}
			#mobile-menu {
				margin-right: 0 !important;
			}
			nav {
				width: 93.3% !important;
				max-width: 1170px!important;
				min-width: 300px!important;
			}
		}
		@media only screen and (max-height: 650px) and (max-width: 991px) and (min-width: 768px) {
			header {
				padding: 0 6.5vw !important;
			}
			nav {
				margin-top: -8px !important;
				width: 100.1% !important;
			}
			.prevNavBtn {
				width: 49.6% !important;
				left: 0.1% !important;
				right: unset !important;
			}
			.nextNavBtn {
				right: 0.1% !important;
				left: unset !important;
				width: 49.6% !important;
				margin-right: 0 !important;
			}
			#mobile-menu {
				margin-right: 0.61vw !important;
			}
		}
	}
	html,
	body {
		background-color: ${(props) =>
		props.theme.colors.brand_purple_00} !important;
		color: ${(props) => props.theme.colors.brand_gray_03}!important;
		font-family: ${(props) => props.theme.fonts.main}!important;
		font-size: 16px!important;
		line-height: 19px!important;
		height: 100%;
		min-width: 320px!important;
		.anchor-link, .anchor-link > *, .anchor-link > * > *, .anchor-link > * > * > *:nth-child(1) {
			display: inline !important;
		}

		@media ${device.mobile} {
			font-size: 12px !important;
			line-height: 16px !important;
		}
	}

	select, input{
		font-family: ${(props) => props.theme.fonts.main}!important;
	}

	body {
		height: 100%;
		overflow: auto;
		opacity: 1;

		&.loaded {
			opacity: 1;
			transition: opacity .1s 0s linear;
		}
		.mobile-show {
			display:none;
			@media ${device.mobile} {
				display:block;
			}
		}
		.purple {
			color: ${(props) => props.theme.colors.brand_purple_00} !important;
		}
	}

	html.landscape {

		.image-modal {
			margin-top: 6%;

			.slick-track > .slick-slide > div {
				overflow-y: scroll;
				height: 74vh;
			}
		}

		.mobile-scan-modal-content {
			background-position-x: -1rem;
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;
			margin-top: 3%;
			max-height: 85vh;
			position: relative;
			width: 67vw;
		}

		.rotate-device {
			display: none!important;
		}

		transition: none!important;
	}

	html.portrait {

		.mobile-scan-modal-content {
			background-position-x: 42%;
			background-size: 104%;
			height: auto;
			margin-top: 25%;
			padding-bottom: 48.5%;
			overflow: hidden;
			width: 90%;
		}

		.mobile-scan-modal-close {
			right: 1%;
		}
	}

	html.menu {
		@media ${device.mobile} {
			overflow: hidden !important;
		}
	}

	html.locked, html.menu-locked {
		overflow: hidden;
		body {
			overflow: hidden;
		}
	}
	.homepage + .transcript {
		.trans-copy{
			@media ${device.mobile} {
				max-height: 225px;
				overflow: scroll;
			}
		}
		.transcript-accordion .transcript{
			&.open{
				@media ${device.mobile} {
					padding: 1.5rem 1rem 1rem 1rem;
				}
				&.inverse{
					@media ${device.mobile} {
						overflow-y: inherit;
					}
				}
			}
		}
	}

	.homepage + .transcript.audiotrayopen .transcript-accordion{
		.transcript {
			@media ${device.mobile} {
				padding: 1.5rem 1rem 1rem 1rem;
			}
		}
		.transcript.inverse{
			@media ${device.mobile} {
				overflow-y: inherit;
			}
		}
	} 

	html {
		&.unbranded-page {
			background-color: #ECE6F4 !important;
			color: #440099 !important;
			#main-wrap {
				background-color: #ECE6F4 !important;
			}
			.audio-player-wrapper {
				border-color: ${(props) => props.theme.colors.brand_purple_00};
			}

			.interstitial-modal-buttons{
				a{
					span{
						color: ${(props) =>
		props.theme.colors.white} !important;
					}
				}
			}
			#num0-interstitial-injector-modal-content-wrap{
				left: 0!important;
			}
			#gatsby-focus-wrapper {
				margin-bottom: 0;
			}
			[data-component="header"] {
				& > ul {
					& > li {
						@media ${device.desktop_xlgmx} {
							&:nth-of-type(1) {
								display: inline;
							}
						}
						&:nth-of-type(2){
							@media ${device.mobile} {
								display:none;
							}
						}
						&:nth-of-type(1){
							@media ${device.mobile} {
								width:100%;
								margin-right:5rem;
							}
						}
						&:nth-of-type(3){
							@media ${device.mobile} {
								width:100%;
							}
						}
					}
				}
			}
			#pi-link{
				display: none;
			}

			.slick-dots{
				margin-top: 3.75rem;
			}
			
			body {
				background-color: #ECE6F4 !important;
				color: #440099 !important;
			}

			footer {
				span, a, p { color: #440099 !important; }
				&.unbranded {
					background-color: #ECE6F4 !important;
				}
			}

			header {
				background-color: #ECE6F4 !important;
				color: #440099 !important;
				#arikayce-logo {
					display: none;
				}
				ul {
					li {
						a {
							color: #440099 !important;
						}
					}
				}
				#audio-title {
					color: #440099 !important;
				}
				&::before {
					background: radial-gradient( circle at bottom right,transparent 2vw,#ECE6F4 2.1vw ) !important;
				}
				&::after {
					background: radial-gradient( circle at bottom left,transparent 2vw,#ECE6F4 2.1vw ) !important;
				}
			}

			#gatsby-focus-wrapper {
				background-color: #ECE6F4 !important;
				color: #440099 !important;
			}
			
			#mobile-menu {
				
				&.open {
					background-color: ${(props) =>
		props.theme.colors.brand_purple_00};
				}
				&> img {
					@media ${device.mobile} {
						filter: none;
					}
				}
				&> span {
					@media ${device.mobile} {
						color: ${(props) =>
		props.theme.colors.brand_purple_00} !important;
					}
				}
			}

			#navigation {	 
				@media ${device.desktop_xlg} {
					background-color: ${(props) =>
		props.theme.colors.brand_purple_00};;
				}
				
				nav {
					background-color: ${(props) =>
		props.theme.colors.brand_purple_00};
					
					&> ul > li > a {
						color: ${(props) =>
		props.theme.colors.white} !important;
					}
				}
			}

			#insmed-logo {
				display:block;
			}

			&.isi-tray-open {

				#isi-tray {
	
					&> .isi-tray-bg {
						background-color: #ECE6F4 !important;
					}
				}
			}

			.autoplay-box a.transcript {
				color: ${(props) => props.theme.colors.brand_purple_00};
			}

			.roundedges {
				border-color: #ECE6F4 !important;
			}
		}
		&.isi-tray-open {
			overflow: hidden!important;

			[data-isi-snippet] {
				display: block;
			}

			header {
				z-index: 111;
			}

			#isi-tray {
				// height: 80.5%;
				height: calc(100% - 200px);

				// @media ${device.desktop_xlgmx} { height: 75.5vh; }
				@media ${device.mobile} { height: 67.25vh; }

				&> .isi-tray-bg {
					position: relative;
					height: 100%;
					left: 0;
					opacity: 1;
					padding: 30rem;
					position: fixed;
					top: 0;
					width: 100%;
					z-index: 0;

					&::after {
						position: absolute;
						left: 0;
						height: 100%;
						width: 20%;
					}

					&::before {
						position: absolute;
						right: 0;
						width: 20%;
						height: 100%;
					}
				}

				.caret {
					transform: rotate(90deg);
				}
			}

			[data-component="isi"] > div:nth-of-type(2) {
				overflow-y: scroll;
				height: 100%;
				padding-right: 1rem;
				padding-left: 1rem;
				@media ${device.desktop_xlgmx} {
					${"" /* height: 88vh; */}
					padding-bottom: 5rem;
				}
			}
		}

		&.isi-tray-collapsed {
			#isi-tray {
				height: 2.5rem;
			}
		}

		&.isi-tray-collapsed.mini-isi {
			#isi-tray {
				height: 82.5%;
			}
		}
	}

	span {
		&.btn-1,
		&.btn-2,
		&.btn-3 {
			align-items: center;
			background-color: ${(props) => props.theme.colors.brand_orange_00};
			border-radius: .35rem;
			color: white;
			display: flex;
			font-size: 1.125rem;
			font-weight: bold;
			height: 3rem;
			justify-content: flex-start;
			line-height: 2rem;
			padding: 0 15% 0 5%;
			position: relative;
			text-decoration: none;
			@media ${device.mobile} {
				font-size:1.166rem!important;
				height: 2.5rem;
				padding: 0 10% 0 5%;
			}
		}
		&.btn-1 {
			&> img {
				height: 100%;
				width: .5rem;
				position: absolute;
				right: 0;
				top: 0;
				margin-right: .75rem;
			}
			&:hover img{
				right: -2px;
			}

			&.disabled {
				color: #a7a7a7;
				background-color: #cdcdcd;
			}
		}

		&.btn-2 {
			font-size: 1rem;
			display: flex;
			flex-direction: column;
			line-height: 1rem;
			padding: .7rem;
			padding-right: 3rem;
			height: auto;

			&::after {
				content: '+';
				border-left: 2px solid ${(props) => props.theme.colors.white};
				font-size: 3rem;
				font-weight: normal;
				height: 100%;
				line-height: 2.75rem;
				padding-left: .5rem;
				position: absolute;
				right: .5rem;
				top: 0;
			}
		}

		&.btn-3 {
			&::after {
			position: absolute;
			content: "";
			background: url(${DownloadIcon}) no-repeat;
			width: 2rem;
			height: 2rem;
			right: .5rem;
			}
		}

		&.btn-4 {
			background-color: ${(props) => props.theme.colors.white};
			border-radius: .35rem;
			color: ${(props) => props.theme.colors.brand_purple_00}!important;
			display: inline-block;
			font-weight: 600;
			line-height: .9rem;
			padding: .5rem 2.25rem .5rem .6rem;
			position: relative;
			font-size: .75rem!important;

			&::after {
				content: '';
				background-image: url("../images/caret-01.svg");
				background-repeat: no-repeat;
				background-size: contain;
				margin: .5rem;
				padding: .45rem;
				position: absolute;
				right: 0;
				top: 0;
			}

			&.download::after {
				background-image: url("../images/icon-download.svg");
				padding: .6rem;
			}

			&:hover{
				&::after {
					right: -7px;
				}
			}
		}

		&.btn-5 {
			background-color: ${(props) => props.theme.colors.brand_purple_00};
			border-radius: .35rem;
			color: ${(props) => props.theme.colors.white};
			display: inline-block;
			font-size: 1.33333rem;
			font-weight: bold;
			letter-spacing: 0;
			padding: 1rem;
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}

			&.active {
				border: 2px solid ${(props) =>
		props.theme.colors.brand_orange_00};
				background-color: ${(props) => props.theme.colors.white};
				color: ${(props) => props.theme.colors.brand_orange_00};
			}
		}
	}

	a {
		color: ${(props) => props.theme.colors.brand_purple_00};
		text-decoration: none;

		&.sup-underline-fix {
			sup {
			display: inline-block;
			border-bottom: 1px solid ${(props) =>
		props.theme.colors.brand_purple_00};
			padding-bottom: .75rem
			}
		}

		&:hover,
		&:focus {
			text-decoration: underline;
		}

		&.btn-1,
		&.btn-2,
		&.btn-3 {
			align-items: center;
			background-color: ${(props) => props.theme.colors.brand_orange_01};
			border-radius: .35rem;
			color: white;
			display: flex;
			font-size: 1.125rem;
			font-weight: bold;
			height: 3rem;
			justify-content: flex-start;
			line-height: 2rem;
			padding: 0 15% 0 5%;
			position: relative;
			text-decoration: none;
			@media ${device.mobile} {
				font-size:1.166rem!important;
				height: 2.5rem;
				padding: 0 10% 0 5%;
			}
		}

		&.btn-1 {
			&> img {
				height: 100%;
				width: .5rem;
				position: absolute;
				right: 0;
				top: 0;
				margin-right: .75rem;
			}
			&:hover img{
				right: -2px;
			}

			&.disabled {
				color: #a7a7a7;
				background-color: #cdcdcd;
			}
		}

		&.btn-2 {
			font-size: 1rem;
			display: flex;
			flex-direction: column;
			line-height: 1rem;
			padding: .7rem;
			padding-right: 3rem;
			height: auto;

			&::after {
				content: '+';
				border-left: 2px solid ${(props) => props.theme.colors.white};
				font-size: 3rem;
				font-weight: normal;
				height: 100%;
				line-height: 2.75rem;
				padding-left: .5rem;
				position: absolute;
				right: .5rem;
				top: 0;
			}
		}

		&.btn-3 {
			&::after {
			position: absolute;
			content: "";
			background: url(${DownloadIcon}) no-repeat;
			width: 2rem;
			height: 2rem;
			right: .5rem;
			}
		}

		&.btn-4 {
			background-color: ${(props) => props.theme.colors.white};
			border-radius: .35rem;
			color: ${(props) => props.theme.colors.brand_purple_00}!important;
			display: inline-block;
			font-weight: 600;
			line-height: .9rem;
			padding: .5rem 2.25rem .5rem .6rem;
			position: relative;
			font-size: .75rem!important;

			&::after {
				content: '';
				background-image: url("../images/caret-01.svg");
				background-repeat: no-repeat;
				background-size: contain;
				margin: .5rem;
				padding: .45rem;
				position: absolute;
				right: 0;
				top: 0;
			}

			&.download::after {
				background-image: url("../images/icon-download.svg");
				padding: .6rem;
			}

			&:hover{
				&::after {
					right: -7px;
				}
			}
		}

		&.btn-5 {
			background-color: ${(props) => props.theme.colors.brand_purple_00};
			border-radius: .35rem;
			color: ${(props) => props.theme.colors.white};
			display: inline-block;
			font-size: 1.33333rem;
			font-weight: bold;
			letter-spacing: 0;
			padding: 1rem;
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}

			&.active {
				border: 2px solid ${(props) =>
		props.theme.colors.brand_orange_00};
				background-color: ${(props) => props.theme.colors.white};
				color: ${(props) => props.theme.colors.brand_orange_00};
			}
		}
	}

	h1 {
		color: ${(props) => props.theme.colors.brand_purple_00};
		font-size: 1.75rem;
		font-weight: 400;
		letter-spacing: 0;

		@media ${device.mobile} {
			font-size: 1.91667rem;
			line-height: 2rem;
		}

		.highlight {
			color: ${(props) => props.theme.colors.brand_orange_00};
			font-size: 1rem;
			font-weight: 600;
			letter-spacing: -.0075rem;
			line-height: 2rem;
			text-transform: uppercase;

			@media ${device.mobile} {
				display: inline-block;
				line-height: 1.25rem;
				margin-bottom: .4167rem;
			}
		}

		sup {
			font-size: 1rem;
		}
	}

	h2 {
		color: ${(props) => props.theme.colors.brand_purple_00};
		font-size: 1.25rem;
		line-height: 1.5rem;
		font-weight: 600;
		letter-spacing: 0;

		@media ${device.mobile} {
			font-size: 1.5833rem;
			line-height: 1.9166rem;
			word-spacing: .15rem;
		}

		sup {
			font-size: .75rem;
		}

		&.bottom-spacer {
			margin-bottom: 2.5rem;
		}
	}

	h3 {
		color: ${(props) => props.theme.colors.brand_orange_00};
		font-size: 1rem;
		line-height: 2rem;

		@media ${device.mobile} {
			font-size: 1.25rem;
		}
	}

	sup {
		&.footnote-symbol {
		font-family: "Helvetica Neue", sans-serif;
		font-size: 8px;
		}
	}

	main {
		background-color: ${(props) => props.theme.colors.white};
		// background-color: ${(props) => props.theme.colors.brand_purple_00};
		border-bottom-left-radius: 5px!important;
		border-bottom-right-radius: 5px!important;
		display: flex;
		flex-direction: column;
		margin-bottom: 0;
		overflow: hidden;
		padding-bottom: 4rem!important;
		padding-top: 3.5rem!important;
		position: relative;
		&#main {
			margin: 12.5rem auto 0 !important;
			width: 90%!important;
			width: ${typeof navigator !== "undefined" &&
		navigator.platform.indexOf("Win") > -1
		? "calc(90% + 15px)!important"
		: "90%!important"
	};
			@media ${device.tablet}{
				margin: 14.5rem auto 0 !important;
			}
			@media ${device.mobile}{
				margin: 26.5rem auto 0 !important;
			}
			section, div:not(#main-content, #main-wrap) {
				z-index: 2;
			}
		}
		.culture-conversion-carousel {
			top:-11.5rem;
			@media ${device.desktop}{
				top:-8rem;
			}
				@media ${device.desktop_lg}{
				top:-15rem;
				}
				@media ${device.desktop_xlg}{
				top:-8rem;
				}
				@media ${device.tablet}{
				top:-3.5rem;
				}
				@media ${device.mobile}{
				top:-7.5rem;
				}
		}
	}

	ol li::marker {
		color: ${(props) => props.theme.colors.brand_orange_00};
		font-weight: bold;
	}

	p {
		letter-spacing: 0;

		@media ${device.mobile} {
			font-size: 1.33333rem;
		}

		strong > a {
			text-decoration: underline;
		}
	}

	#gatsby-focus-wrapper {
		background-color: inherit !important;
	}

	#hero-container {
		background-color: white;
		background-image: linear-gradient(to bottom, #ece5f4 1%, #fff 66%);
		border-top-left-radius: .25rem;
		border-top-right-radius: .25rem;
		padding-right: 1.25rem;
		padding-top: 3rem;
		padding-top: 5%;
		position: relative;
		text-align: center;
		&> img {
			margin: 0 auto;
			max-width: 100%;
			width: 100%;
		}
	}

	#definitions-home {
		margin: 3.313rem 0 0 0;
		color: ${(props) => props.theme.colors.white};
		padding-left: 0;
	}

	#pi-link {
		font-weight:bold;
		font-size: 1rem;
		margin-top: 1rem;

		@media ${device.mobile} {
			font-size: 1.33333rem;
		}
		
		a {
			color: ${(props) => props.theme.colors.brand_purple_00};
			text-decoration:underline;
		}

		&.home-pi-link {
			color: ${(props) => props.theme.colors.white};
			a {
				color: inherit;
			}
		}
	}

	#overlay {
		position: fixed; 
		display: none; 
		width: 100%; 
		height: 100%; 
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(68,0,153,.5); 
		z-index:99;

		&.active{
			display:block;
		}
	}

	#references {	
		padding-left: .55rem;

		&.references-home {
			padding-left: 0;

			.references {
				color: ${(props) => props.theme.colors.white};
			}
		}

		.references {
			color: ${(props) => props.theme.colors.brand_gray_03};
			line-height: .875rem;
		}
	}

	#toc-container {
		color: ${(props) => props.theme.colors.brand_purple_00};
		background-color: ${(props) => props.theme.colors.white};
		padding-bottom: 1rem;
		padding-top: 1rem;
		max-width: 70.5rem !important;
		padding-left: 1rem;
		padding-right: 1rem;
		
		@media ${device.mobile} {
			margin-top:1.875rem;
		}
			
	}
	.references-home > div{
		color: ${(props) => props.theme.colors.white};;
	}
	.definitions-home > .definitions > p {
		margin-bottom:0;
	}

	.definitions > p {
		@media ${device.mobile} {
			line-height: 1.25rem;
		}
	}

	.references {
		@media ${device.mobile} {
			line-height: 1.15rem;
		}
	}

	.slick-track { 
		display: flex;
		margin: 0 auto; 
	}

	ul {
		padding-left: 1rem;

		&.slick-dots {
		li {
			cursor: default !important;

			&::before {
			content: "";
			}

			button {
				cursor: default !important;
			}

			button:before {
			color: transparent !important;
			border: 1px solid ${(props) => props.theme.colors.brand_orange_00};
			width: 10px;
			height: 10px;
			border-radius: 100%;
			opacity: 100%;
			}
		}

		li.slick-active button:before {
			background-color: #E35203 !important;
			width: 10px;
			height: 10px;
			border-radius: 100%;
			opacity: 100%;
		}
		}

		li {
			list-style-type: none!important;
			position: relative;
			margin-bottom: .75rem;

			&:before {
				content: '•';
				color: ${(props) => props.theme.colors.brand_orange_00};
				font-size: 2rem;
				margin-left: -1.15rem;
				position: absolute;
				left: 0;
				top: -3px;

				@media ${device.mobile} {
					font-size: 3rem;
					line-height: 1.15rem;
					top: -2px;
				}
			}

			> ul {
				li {
				&.sub-bullet {
					&:before {
					content: '-';
					color: ${(props) => props.theme.colors.brand_orange_00};
					font-size: 2rem;
					margin-left: -1.15rem;
					position: absolute;
					left: 0;
					top: -3px;
					@media ${device.mobile} {
						top: 0px;
					}
					}
				}
				}
			}
		}
	}

	section:not(.nomargin) {
		margin: 6rem auto;

		@media ${device.tablet}	 { margin: 2.5rem auto 0; }
		@media ${device.desktop}	{ margin: 2.5rem auto 0; }
		@media ${device.desktop_lg} { margin: 2.5rem 0 0; }
		@media screen and (min-width: 1200px) and (max-width: 1280px) {
			margin: 2.5rem 0 0;
		}
		@media ${device.mobile} {
			margin: 5rem auto 6rem;
		}
		h1 {
			margin-bottom: 1.25rem;

			@media ${device.mobile} {
				margin-bottom: 0;
			}
		}
		
	}


	small {
		font-size: .875rem;
	}

	sub,
	sup {
		line-height: 0 !important;
		font-size: x-small;
	}

	[data-component="callout"] {
		border-radius: .35rem;
		padding: 2rem;

		&.color-purple {
			color: ${(props) => props.theme.colors.brand_purple_00};
		}

		&.green {
			border: 2px solid ${(props) => props.theme.colors.brand_green_00};
		}

		&.purp {
			background-color: ${(props) => props.theme.colors.brand_purple_01};
		}

		&.white {
			border: 2px solid ${(props) => props.theme.colors.brand_orange_00};
			margin: 0 1rem;
			padding: 2rem 2rem .5rem 2rem;

			@media ${device.mobile} {
				padding-left: 1rem;
				padding-right: 1rem;
			}
		}

		h2 {
			> span {
			&.gray-header {
				color: ${(props) => props.theme.colors.brand_gray_03};
			}
			}
		}
		p {
		> span {
			&.purple {
			color: ${(props) => props.theme.colors.brand_purple_00};
			}
		}
		}
	}

	[data-component="tabs"] {

		&> ul {
			align-items: flex-end;
			display: flex;
			padding: 0;

			&> li {
				background-color: ${(props) =>
		props.theme.colors.brand_purple_00};
				border-left: 2px solid ${(props) =>
		props.theme.colors.brand_purple_00};
				border-right: 2px solid ${(props) =>
		props.theme.colors.brand_purple_00};
				border-top-left-radius: .25rem;
				border-top-right-radius: .25rem;
				border-top: 2px solid ${(props) =>
		props.theme.colors.brand_purple_00};
				color: ${(props) => props.theme.colors.white};
				cursor: pointer;
				font-size: 1.25rem;
				font-weight: bold;
				line-height: 3rem;
				padding: 0 .85rem;
				margin-bottom: 0;

				&::before {
					content: none;
				}

				&.active {
					background-color: ${(props) => props.theme.colors.white};
					color: ${(props) => props.theme.colors.brand_purple_00};
					cursor: default;
				}
			}
		}

		&> div {
			border: 2px solid ${(props) => props.theme.colors.brand_purple_00};
			border-radius: .35rem;
			border-top-left-radius: 0;
			margin-top: -.15rem;
			padding: 3rem 4rem 3.75rem 4rem;

			[data-tab-content] {
				display: none;

				&.active {
					display: block;
				}

				p {
					a {
					text-decoration: underline;
					}
				}
			}
		}

	}

	.hidden {
		display: none;
	}

	.md-container {
		padding-top: 0;
	}

	.seo {
		position: absolute !important;
		left: -999999px!important;
		top: -999999px!important;
	}

	.table-row	 { display: table-row; }
	.table-heading { display: table-header-group;}
	.table-body	{ display: table-row-group; }

	.table-cell,
	.table-col-head { display: table-cell; }

	.table-00 {
		border-collapse: collapse;
		border-radius: .15rem;
		// any larger radius and it cuts off the borders
		// border-radius: .35rem;
		height: 200%;
		overflow: hidden;
		position: relative;
		text-align: left;
		width: 100%;

		&::after {
			content: '';
			border: 2px solid ${(props) => props.theme.colors.brand_purple_00};
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		.table-cell,
		.table-col-head {
			padding: 3px 2px;
		}

		.table-cell {
			line-height: 2rem;
			padding: 0 1rem;
			width: 33%;

			&:first-child {
				/* border-left: 1px solid ${(props) =>
		props.theme.colors.brand_purple_00}; */
			}
			&:nth-child(2) {
				border-left: 2px solid ${(props) =>
		props.theme.colors.brand_purple_00};
				border-right: 2px solid ${(props) =>
		props.theme.colors.brand_purple_00};
			}
			&:last-child {
				/* border-right: 1px solid ${(props) =>
		props.theme.colors.brand_purple_00}; */
			}
		}

		.table-row:nth-child(even) {
			background: ${(props) => props.theme.colors.brand_purple_01};
		}

		.table-heading {
			background: ${(props) => props.theme.colors.brand_purple_00};

			.table-col-head {
				border-left: 2px solid ${(props) => props.theme.colors.white};
				color: ${(props) => props.theme.colors.white};
				font-weight: bold;
				line-height: 3rem;
				text-align: center;
				width: 33%;

				&:first-child {
					border-left: none;
					border-top-left-radius: .25rem;
				}

				&:last-child {
					border-top-right-radius: .25rem;
				}
			}
		}

		display: table;
	}

	.tiny {
		font-size: .75rem;
		line-height: 1.1;
		color: ${(props) => props.theme.colors.white};
		@media ${device.mobile} {
			font-size:1.0833rem;
		}
		a {
			text-decoration: underline;
		}
	}

	.hero-wrapper {
		@media ${device.betweenTabletAndDesktop} {
			margin-top: 0 !important;
			padding-top: 0 !important;
		}
	}

	.wrapper,
	.form-wrapper,
	.hero-wrapper {
		// border-radius: 5px;
		margin: 0 auto;
		max-width: 1170px!important;
		min-width: 300px!important;
		width: 90%!important;
		padding: 0 1.75rem;

		@media ${device.mobile} {
			padding: 0 1rem;
		}
	}

	.form-wrapper {
		padding: 0;
	}

	.roundedges {
		border-bottom: none!important;
		border-top-left-radius: 20px!important;
		border-top-right-radius: 20px!important;
		border: 10px solid #440099;
		height: 20px;
		left: 0;
		margin: 0 auto;
		max-width: 1190px!important;
		min-width: 300px!important;
		position: relative;
		top: 1rem;
		width: calc(100% + 20px)!important;


		@media ${device.mobile} { top: 8rem; }

		@media only screen and (min-width: 300px) and (max-width: 374px) {
			/* bottom: -8.25rem !important; */
			width: 96.7%!important;
			min-width: auto!important;
		}

		@media only screen and (min-width: 375px) and (max-width: 414px) {
			/* bottom: -8.2rem !important; */
			width: 92.5%!important;
		}

		@media only screen and (min-width: 415px) and (max-width: 570px) {
			/* bottom: -9rem !important; */
			width: 91.8%!important;
		}

		@media only screen and (min-width: 571px) and (max-width: 767px) {
			/* bottom: -9rem !important; */
			width: 91.5%!important;
		}

		@media only screen and (min-width: 768px) and (max-width: 810px) {
			width: 91.3%!important;
			/* bottom: -144px!important; */
		}

		@media only screen and (min-width: 811px) and (max-width: 870px) {
			width: 91.2%!important;
			/* bottom: -144px!important; */
		}

		@media only screen and (min-width: 871px) and (max-width: 991px) {
			width: 91.1%!important;
			/* bottom: -144px!important; */
		}

		@media only screen and (min-width: 991px) and (max-width: 1130px) {
			width: 91%!important;
			/* bottom: -144px!important; */
		}

		@media only screen and (min-width: 1130px) and (max-width: 1265px) {
			/* bottom: -144px!important; */
		}
	}

	.secondary .roundedges {
		top: 1.25rem;
		pointer-events: none;
		left: -.5%;

		@media screen and (min-width: 992px) and (max-width: 1279px) {
			left: -.65%;
			top: 7rem;
			width: 90.5% !important;
		}

		@media screen and (max-width: 991px) {
			display: none;
		}
	}

	.centered {
		text-align: center;
	}

	.desktoponly{
		display: block;
		@media ${device.mobile} {
			display: none !important;
		}
	}

	.mobileonly{
		display: none !important;
		@media ${device.mobile} {
			display: block!important;
		}
	}

	.wrapper.homepage {
		padding-left: 1.5rem;
		width: 94.5% !important;
	}

	#important-safety-information-toggle-container {
		>div { padding-top: .0938rem; cursor: pointer; }

		@media ${device.mobile} {
		line-height: .9rem;
		}
	}

.ar-popup-button, .ar-popup-button p strong {
	font-family: 'Montserrat';
}

.content-wrapper {
	h3 {
		font-family: 'Montserrat';
	}
	p{
		font-family: 'Montserrat';
	}
}

.ar-popup-button,
.ar-popup-button p strong {
	font-family: 'Montserrat';
}

.third-party-link {
	cursor: pointer;
}

[data-tab],
[data-tab-index] {
	display: none;
	opacity: 0;
	transition: all .2s .2s ease-in-out;
	padding-top: 1rem;
	z-index: 2;
	@media (max-width: 1280px) {
		padding-top: 0;
	}


	&.show		{ display: block; }
	&.visible	{ opacity: 1; }
}
`;

export const wrapPageElement = ({ element }) => (
	<ThemeProvider theme={Theme}>
		<GlobalStyles />
		{element}
	</ThemeProvider>
);
