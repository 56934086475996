const theme_1 = {
  fonts: {
    main: `greycliff, sans-serif`,
    code: `Roboto Mono, monospace`,
  },
  colors: {
    brand_black_00: "#333132",
    brand_black_01: "#000",
    brand_gray_00: "#939598",
    brand_gray_01: "#f6f6f6",
    brand_gray_02: "#d4d4d4",
    brand_gray_03: "#262626",
    brand_gray_04: "#d8d8d8",
    brand_gray_05: "#515151",
    brand_green_00: "#0093a3",
    brand_orange_00: "#d34604",
    brand_orange_01: "#DD5426",
    brand_orange_02: "#CE4A03",
    brand_purple_00: "#440099",
    brand_purple_01: "#ece5f4",
    brand_purple_02: "rgba(68, 0, 153, .5)",
    brand_white_00: "#fefefe",
    white: "#fff",
    main_1: `blue`,
    main_2: `green`,
    dark_1: `purple`,
    dark_2: `red`,
    light_1: `white`,
    light_2: `pink`,
    yellow: `#F7A800`,
  },
  animations: {
    button: `box-shadow .3s ease`,
    link: `color .2s ease`,
    isiOpen: `height .25s .25s linear`,
    all: `all 0s .25s linear`,
  },
  shadows: {
    shadow_1: `0 2px 4px 0 rgba(0, 0, 0, 0.3)`,
  },
  transitions: {
    transition_1: `.2s 0s ease`,
  },
};

export default theme_1;
